import type { BlockMedicalCardCta } from 'types/block-medical-card-cta';
import React, { useMemo, useEffect, useState } from 'react';
import { CardProps, Card, Paragraph, Wrap } from '@leafwell/components';
import Figure from './figure';
import HeadingBody from './heading-body';
import StateSelect from './state-select';
import { getAppUtm } from 'hooks/useCurrentUtm';
import { useWordPressContext } from 'contexts/wordpress-provider';

export type MedicalCardProps = BlockMedicalCardCta & {
  wrap?: boolean;
  buttonLabel?: string;
};

const MedicalCardHighlight: React.FC<Omit<MedicalCardProps, 'acfFcLayout'>> = ({
  description,
  image,
  title,
  variant = 'default',
  imageAlignment = 'right',
  backgroundColor = 'light',
  wrap = true,
  buttonLabel = null,
}) => {
  const { translations } = useWordPressContext();
  const Wrapper = wrap ? Wrap : React.Fragment;
  const isMinimal = variant === 'default';
  const isSmall = variant === 'boxed_small' || variant === 'shortcode';

  const cardVariant = useMemo(() => {
    switch (backgroundColor) {
      case 'gradient':
      case 'gradient-transparent':
        return 'light';
      default:
        return backgroundColor;
    }
  }, [backgroundColor]);

  const cardSettings = useMemo(() => {
    switch (variant) {
      case 'boxed':
        return {
          className: 'md:grid-cols-2 md:mt-12',
          filled: true,
          rounded: true,
          size: 'large' as CardProps['size'],
          variant: cardVariant as CardProps['variant'],
        };
      case 'boxed_small':
        return {
          className: 'md:mt-8 md:grid-cols-3',
          filled: true,
          rounded: true,
          size: 'medium' as CardProps['size'],
          variant: cardVariant as CardProps['variant'],
        };
      case 'shortcode':
        return {
          className:
            'md:grid-cols-2 md:mt-12 md:!gap-x-3 md:!pr-4 mb-6 not-prose',
          filled: true,
          rounded: true,
          size: 'large' as CardProps['size'],
          variant: cardVariant as CardProps['variant'],
        };
      default:
        return {
          className: 'md:grid-cols-2 gap-y-10',
          variant: 'minimal' as CardProps['variant'],
        };
    }
  }, [variant]);

  const imageClassNames = useMemo(() => {
    const styles = ['order-first md:order-none'];

    const boxedStyles = 'hidden md:block self-end';

    switch (variant) {
      case 'boxed':
        styles.push(boxedStyles);
        styles.push('md:!-mt-24 md:!-mb-10');
        break;
      case 'boxed_small':
        styles.push(boxedStyles);
        styles.push('md:-mt-16 md:-mb-6 max-w-56 justify-self-center');
        break;
      case 'shortcode':
        styles.push(boxedStyles);
        styles.push(
          'not-prose md:!-mt-24 md:!-mb-10 max-w-64 justify-self-end',
        );
        break;
    }

    if (imageAlignment === 'left') {
      styles.push('order-1');
    }

    return styles.join(' ');
  }, [imageAlignment, variant]);

  const [hideCardCta, toggleCardCta] = useState(false);

  useEffect(() => {
    if (getAppUtm()) {
      toggleCardCta(true);
    }
  }, []);

  if (hideCardCta) {
    return null;
  }

  return (
    <Wrapper>
      <Card
        {...cardSettings}
        className={[
          'leafwell-card-cta',
          'md:gap-x-10 xl:gap-x-16 md:items-center md:content-center',
          cardSettings.className,
          backgroundColor === 'gradient' ||
          backgroundColor === 'gradient-transparent'
            ? 'relative before:absolute before:md:w-3/4 before:h-full before:md:right-0 before:md:bottom-0 before:md:rounded-2xl before:bg-gradient-to-b before:from-lilac-100 before:via-indigo-100 before:to-green-300 before:gradient-mask-l-0'
            : '',
          backgroundColor === 'gradient-transparent'
            ? 'lg:bg-transparent lg:border-transparent'
            : '',
        ].join(' ')}
      >
        <div
          className={[
            'grid',
            isSmall ? 'gap-y-3' : 'gap-y-6 ',
            variant === 'boxed_small'
              ? 'md:col-span-2 md:justify-self-center'
              : '',
            imageAlignment === 'left' ? 'order-2' : '',
            isMinimal ? 'lg:max-w-md' : '',
          ].join(' ')}
        >
          <div>
            <HeadingBody
              level={isSmall ? '3' : '2'}
              tagName="h2"
              title={title}
            />
          </div>
          <Paragraph
            className="my-reset"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <section className="self-start grid gap-y-3">
            {isMinimal && (
              <Paragraph size="large" className="font-medium">
                {translations['Register online to qualify']}
              </Paragraph>
            )}
            <StateSelect buttonLabel={buttonLabel} />
          </section>
        </div>
        <Figure {...image} className={imageClassNames} />
      </Card>
    </Wrapper>
  );
};

export default MedicalCardHighlight;
